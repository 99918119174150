html,body{
  background-color: rgb(12 12 12);
  color:#f0f0f0;
  /*padding: 10px;*/
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.farming-items-list{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}
.farming-items-list-buttons{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}
.farming-items-list-buttons button{
  background-color: #61dafb;
  /*color: #282c34;*/
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.15em;
  margin:auto;
}
.farming-items-list-buttons button:first-of-type{
  background-color: #3ab68c;
  color: #f0f0f0;
  border-radius: 5px;
  font-weight: bold;
}
/*.farming-items-list-buttons button:last-of-type{*/
/*  background-color: #f48c36;*/
/*  color: #282c34;*/
/*  border-radius: 5px;*/
/*}*/

.active-items-stages{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  overflow-y: scroll;
}
.active-items-stages div{
  width:10px
}
.list-active-items{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  max-width: 33%;
}
.list-active-items div:first-of-type div{
  font-size: 25px;
}

.wrapper-list-active-items{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.wrapper-list-active-items.hidden{
  display: none;
  visibility: hidden;
}

.sell-price-fluctuation{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*gap: 10px;*/
    /*margin-top: 10px;*/
  width: 100%;
}
.sell-price-fluctuation button{
    /*color: #282c34;*/
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.15em;
    margin:auto;
  color: #f0f0f0;
  font-weight: bold;
  width: 100%;

  box-shadow: 1px 1px 11px 7px #5c4736;
  background-color: #a35b20;

}
.sell-price-fluctuation button:disabled,
.sell-price-fluctuation button[disabled]{
    background-color: rgba(173, 123, 85, 0.57);
  box-shadow: 0px 0px 0px 0px #f48c36;

}
.sell-price-fluctuation .loss{
    color: #f48c36;
  font-size: 18px;
}
.sell-price-fluctuation .gain{
    color: #3ab68c;
  font-size: 18px;
}
.active-item-actions{
  flex: 1;
  text-align: end;
}
.active-item-actions button{
  border-radius: 50px;
  height: 45px;
  width: 45px;
  text-align: center;
  font-size: 20px;

  background-color: #5d8e95;
  border: 0px;
  box-shadow: 1px 1px 13px 6px #36686e;
}
.active-item-actions button:disabled,
.active-item-actions button[disabled]{
  background-color: rgba(112, 198, 209, 0.4);
  color: #f0f0f0;
  box-shadow: 0px 0px 0px 0px #5cccc0ba;
}

.active-item-actions > .animal-feed{

  background-color: #959e4ee6;
  box-shadow: 1px 1px 20px 8px #7f883fe6;
}
.icon-timer{
    font-size: 20px;
    color: #f0f0f0;
  display: flex;
    justify-content: center;
    align-items: center;
  flex-direction: column;
  gap:5px;
  width: 100%;
}
.buttons-items{

  display: flex;
  gap: 10px;
  width: 27vw;
  flex: 6;
}
.icon-border{
    /*border: 3px solid #a6d556;*/
  border: 3px solid;
  border-image: linear-gradient(to right, #676931 30%, #556007 30%, #516c3e 50%, #5f5915 100%) 1;
    border-radius: 5px;
    /*padding: 5px;*/
  width: 90%;
  height: 50px;
text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  text-shadow: 2px 2px 20px #697626;
  background-color: #322b11;
}

.root_timer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.seconds {
  position: absolute;
}

.svg_timer {
  transform: scale(-1, 1);
  overflow: visible;
}

.countDownContainer {
  transform: rotate(90deg);
}
.wrapper-farming-all{
  padding: 13px;
  padding-bottom: 70px;
  padding-top: 100vh;
}
.wrapper-farming-all > h2 {
  font-size: 1.17em;

  text-align: start;
  margin-bottom: 0;
  margin-top: 0;
}

.wrapper-farming-all > h2:after{
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: rgba(240, 240, 240, 0.68);
    margin-top: 5px;
}

.home-pretty-top{
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 13px;
  border-left: 0px;
  border-top: 2px;
  border-color: #3ab68c;
  border-style: solid;
  border-right: 0px;
  border-bottom: 0px;
  box-shadow: -1px -12px 14px 0px #D5FFE4;
  margin-top: 90px;
  background: #7f838226;
}

.over-level-bar{
  font-size: 0.9em;
  margin: 0px;
  text-align: center;
  color: #f1bb19;
  font-weight: 900;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  text-wrap: nowrap;
  text-shadow: -1px -1px 9px black;
  padding-left: 10px;
}


.tap-coin-earn{
  position: fixed;
  bottom: 80px;
  right:10px;
}
.tap-coin-earn button{
    background-color: #f4d34b;
    color: #f0f0f0;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 3.15em;
    box-shadow: 1px 1px 11px 7px #5c4736;
  padding: 0;
  margin: 0;
}
.tap-coin-earn span{
    font-size: 1.0em;
    display: block;
  background: rgba(0, 0, 0, 0.82);
    text-align: center;
    color: #f0f0f0;
    text-shadow: 1px 1px 9px black;
}

.tap-coin-earn-large{

}
.tap-coin-earn-large button{
    background-color: #f4d34b;
    color: #f0f0f0;
    border: none;
    border-radius: 150px;
    cursor: pointer;
    font-size: 15.15em;
    box-shadow: 1px 1px 11px 7px #5c4736;
  padding: 0;
  margin:10px  0 ;
}
.tap-coin-earn-large span{
    font-size: 2.0em;
    display: block;
  background: rgba(0, 0, 0, 0.37);
    text-align: center;
    color: #f0f0f0;
    text-shadow: 1px 1px 9px black;
  border-radius: 15px;
}


.page-content{
  margin-bottom: 80px;
}

.footer{
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  bottom: -2px;
  background: linear-gradient(rgb(21 21 21 / 89%) 40%, rgb(21 21 21 / 97%) 55%, rgb(21 21 21) 75%);
  width: 100vw;
  padding: 7px 0px;
  justify-content: space-around;
  align-items: center;
}

.footer button{
  background: transparent;
  color: #f0f0f0;
  font-size: 18px;
  border: #3ab68c;
  font-weight: bold;
}
.footer button img{
  width: 40px;
  height: 40px;
}
.footer button img.dark{
  filter: brightness(0.3);
}
@keyframes flash {
  0%, 49.9% {
    background-color: rgba(255, 255, 255, 0.95);
  }
  50%, 100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
.flash {
  width: 6px;
  height: 6px;
  border-radius: 50px;
  animation: flash 1.3s infinite;
  position: absolute;
  top: 12px;
  right: calc(100vw - 43%);
}
.flash.n{
  width: 8px;
  height: 8px;
  border-radius: 50px;
  animation: flash 1.3s infinite;
  position: absolute;
  top: 12px;
  right: calc(100vw - 68%);
}
.bnf3-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
.bnf3-token{
  margin-top: 50px;

  position: relative;
}
.bnf3-token img:first-of-type{
  width: 55vw;
  height: 55vw;
  box-shadow: inset 0px 1px 20px 12px #f4cb3a;
  border-radius: 110px;
}
.bnf3-token-boss{
  position: absolute;
  width: 42px;
  height: 42px;
  bottom: -5px;
  right: 25px;
  background-color: #c1baa4;
  border-radius: 100px;
  box-shadow: 3px 3px 13px 0px white;

}

.my-points{
  width: 80vw;
    margin: 0 auto;
  background: linear-gradient(45deg, #a3e82a, #14ee60);
  border-radius: 10px;
  padding:20px;
  color: #626060;
  font-size: 20px;
  font-weight: bolder;
  box-shadow: 1px 1px 20px #14ee60;
}

.wrapper-miner-list{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
}

.miner-list{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 10px 10px;
  padding-left: 10px;
  background: #252726a6;
  border-radius: 21px;
  margin: 5px 7px;
}
.miner-list-item-top{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-bottom: 7px;
  border-bottom: 1px solid gray;
}

.miner-list-item-name{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
}
.miner-list-item-acquire{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding-top: 7px;
}
.miner-list-item-acquire_first-child{
  border-right:1px solid gray;
  padding-right:20px ;
}
.miner-list-item-acquire_second-child{
  padding-left: 15px;
}

.miner-list img{
    width: 80px;
    /*height: 40px;*/
    /*box-shadow: 1px 1px 20px #14ee60;*/
padding-right: 5px;

}

.miner-popup{
  visibility: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    background: #282c34;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 1px 1px 20px #14ee60;
    color: #f0f0f0;
  position: fixed;

  width: calc(100vw - 39px);
  height: 67vh;
  bottom: -7px;
  z-index: 2;
}
.miner-popup.visible{
    visibility: visible;
}
.miner-popup-inner-image{
  height: 23vh;
  width: calc(100vw - 40px);
  background: #1515158c;
  border-radius: 20px;
  margin-top: 24px;
}
.miner-popup-inner-image img{
  height: 23vh;
}

.miner-popup-inner-close{
  position: absolute;
  right: 25px;
  border-radius: 100px;
  border: 1px solid black;
  width: 30px;
  height: 25px;
  background: #151515;
  line-height: 25px;
  padding-right: 1.5px;
  padding-left: 1px;
  font-weight: bolder;
}

.miner-popup-inner-title{
  margin-top: 25px;
  font-weight: bolder;
  font-size: 20px;
}

.home-pretty-top.miner{
  padding:0px;
  padding-top:13px;
}
.miner-popup-inner-description{
  font-weight: 500;
  font-size: 17px;
  margin-top:10px;
}
.miner-popup-inner-profit-per-hour{
  margin-top:15px
}
.miner-popup-inner-buy{
    margin-top:15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.miner-popup-inner-buy button{
  width: 100%;
  height: 50px;
  color: rgba(21, 21, 21, 0.85);
  font-weight: bolder;
  font-size: 20px;
  border:0;
  background: linear-gradient(45deg, #a3e82a, #14ee60);
  border-radius: 15px;
}

.miners-profit{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 45%;
    padding: 10px;
    background: #252726a6;
    border-radius: 21px;
    margin: 5px 10px;
}
.miners-profit-first{
  border-right: 1px solid gray;
  padding-right: 10px;
}
.miners-profit-second{
  text-align: center;
  width: 100%;
  border-right: 1px solid gray;
}
.home-pretty-top.golden{
  box-shadow: 0px -10px 20px 4px #ffd700d4;
  border: gold;
}

.task-list-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #252726a6;
    border-radius: 21px;
    margin: 15px 10px;
}
.task-list-item.done{
  color: #252726a6;
}

.task-list-item img{
    width: 65px;
    /*height: 50px;*/
    border-radius: 5px;
    box-shadow: 1px 1px 20px #ffd700d4;
}

.task-list-item.done {
  color: rgba(240, 240, 240, 0.38);
  filter: grayscale(10%);
}
.task-list-item.done img{
  filter: grayscale(40%);
  box-shadow: 1px 1px 20px rgba(255, 215, 0, 0.26)
}
.task-list-item-done{
  color: #3ab68c;
  font-weight: bolder;
  font-size: 38px;
  position: absolute;
  left: 30px;
  text-shadow: 4px -8px 20px #3ab68c;
}

.loading-img{
  width: 80vw;
  height: 40vh;
  border-radius: 40px;
}
.loading-screen{
  padding-top: 10vh;
  background: #282c34;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

}

.level-header{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: stretch;
  justify-content: space-around;
}
.level-header img{
  width: 250px;
}
.task-list-item.leaderboard{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.task-list-item.floating{
  position: fixed;
  bottom:70px;
  width: 84vw;
}
.task-list-item.golden{
  box-shadow: inset 1px 1px 20px goldenrod;
}

.wrapper-farming-grid{
  width: 100vw;
  height:100vh;
  position: absolute;
}
.wrapper-farming-grid img{
  width: 100%;
  height: 100%;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.home-pretty-top.has_farm{
  padding-top:0px;
  padding-left: 0px;
  overflow: hidden;
  max-height: calc(100vh - 100px);
}
.farm-crops-on-grid{
  position: absolute;
  top: 17vh;
  left: 51px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100vw - 22vw);
  height: calc(100vh - 29vh);
  overflow: hidden;
  gap: 0px;
  align-content: flex-start;
  align-items: flex-end;
  justify-content: flex-start;
}
.farm-crops-on-grid.fruits{
  top: 25vh;
  left: 25vw;
  height: calc(100vh - 48vh);
  width: calc(100vw - 51vw);
}
.farm-crops-on-grid.animals{
  top: 41vh;
  left: 16vw;
  height: calc(100vh - 61vh);
  width: calc(100vw - 39vw);

}
.crop-on-grid{
width: 1.2em;
  height: 30px;
}
/*.crop-on-grid:nth-of-type(12){*/
/*  margin-left: 0.6em;*/
/*}*/
/*.crop-on-grid:nth-of-type(24){*/
/*  margin-left: 0.6em;*/
/*}*/
/*.crop-on-grid:nth-of-type(47){*/
/*  margin-left: 0.6em;*/
/*}*/
/*.crop-on-grid:nth-of-type(58){*/
/*  margin-left: 0.6em;*/
/*}*/
/*.crop-on-grid:nth-of-type(81){*/
/*  margin-left: 0.6em;*/
/*}*/
/*.crop-on-grid:nth-of-type(92){*/
/*  margin-left: 0.6em;*/
/*}*/
/*.crop-on-grid:nth-of-type(115){*/
/*  margin-left: 0.6em;*/
/*}*/
/*.crop-on-grid:nth-of-type(126){*/
/*  margin-left: 0.6em;*/
/*}*/
/*.crop-on-grid:nth-of-type(149){*/
/*  margin-left: 0.6em;*/
/*}*/
.crop-on-grid img{
  max-height: 28px;
  width: 1.1em;
}
.crop-on-grid.fruits{
  width: 2.0em;
  height: 47px;
}

.crop-on-grid.fruits img {
  max-height: 68px;
  width: 1.9em;
}
.crop-on-grid.animals{
  width: 2.3em;
  height: 35px;
}

.crop-on-grid.animals img {
  max-height: 41px;
  width: 2.5em;
}

.task-list-item.tabs div:last-of-type{
  border-right: 0px;
}
.task-list-item.tabs div{
  border-right: 1px solid gray;
  color:gray;
}
.task-list-item.tabs div.active{
  color: #f0f0f0;
}
.task-list-item.tabs{
  font-size: 18px;
  position: fixed;
  bottom: 64px;
  width: 69vw;
  background: #252726f0;
}

.task-list-item.disabled{
    color: rgba(240, 240, 240, 0.38);
    filter: grayscale(80%);
}
.flash.x{
  position: static;
}

.floating-buyers-menu{
  position: fixed;
  background: #252726f0;
  right: 0px;
  max-height: 60vh;
  overflow: scroll;
  border-radius: 14px;
  padding: 10px;
}
.buyer-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
  padding-top: 5px;
}
.buyer-item h4{
  margin:0;
}
.buyer-item img{
    width: 20px;
    height: 50px;
}
.buyer-item.animals img,
.buyer-item.fruits img
{
  width: auto;
}

.buyer-item.sell img:first-of-type{
  width: 50px;
}
.buyer-item button{
  background: linear-gradient(45deg, #686e4f, #cad21f);
  border: 0px;
  border-radius: 20px;
  padding: 8px 20px;
  color: #f0f0f0;
  font-weight: bolder;
  font-size: 14px;
}
.flash.xx{
  position: absolute;
  right: 8px;
  top: 58px;
    width: 10px;
  height: 10px;

}

.miner-popup.barn{
  width: calc(100vw - 39px);
  left: 0px;
  background: #393423f2;
  overflow: scroll;
}
.wrapper-produce-for-sale{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /*max-width: calc(100vw - 5em);*/
  margin-top: 22px;

}
.produce-for-sale{
background:#252726a6;
  border-radius: 21px;
  padding: 10px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  /*gap: 5px;*/
}
.produce-for-sale img{
  height: 50px;
  width: auto;
}
.produce-for-sale button{
    background: linear-gradient(45deg, #686e4f, #cad21f);
    border: 0px;
    border-radius: 20px;
    padding: 8px 20px;
    color: #f0f0f0;
    font-weight: bolder;
    font-size: 14px;
  width: 100%;
  margin: 10px 0px;
}